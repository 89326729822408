.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}
.maxlogo {
 
}
#col-1 {
    width: 60%;
    height: 100%;
  }
  
  #col-2 {
    width: 40%;
    height: 100%;
    display:flex;
    justify-content: center;
   
  }
  #parentbandhan{
    width: 100%;
    height: 100%;
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }

  .body-background-color{
    background-color: #ffffff;
  }

  @media (max-width: 768px){
    #col-1 {
        width: 100%;
    }

    #col-2{
        width: 100%;
    }
    #imgStyle{
        height:60vh !important;
    }

    .heading-right h1{
        padding-top: 20px;
    }
    .max-logo{
        right: 15px !important;
    }
  }

  @media (max-width: 1024px){
    #col-1 {
        width: 100%;
    }

    #col-2{
        width: 100%;
    }
    #imgStyle{
        height: 60vh !important;
    }
    .heading-right h1{
        padding-top: 20px;
    }
    .max-logo{
        right: 15px !important;
    }
  }

  #imgStyle{
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }


  /* start now page css */
  .max-logo{
    position: absolute;
    top: 15px;
    right: 50px;
    background-color: #ffffff;
    border-radius: 20px;
    width: 100px;
    justify-content: center;
    height: 60px;
    align-items: center;
    display: flex;
  }
.max-logo #maxlogo{
    max-width: 70px;
}

.heading-right{
    padding: 0 50px;
    text-align: center;
  }

.heading-right h1{
    color: #f27930;
    font-weight: bold;
    text-transform: uppercase;
  }

.heading-right p{
    color: #f27930;
    margin-top: 30px !important;
  }

.btn.button-color{
    color: #ffffff;
    padding-left: 40px;
    padding-right: 40px;
  }
 
.popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 28%;
   
}

.popup-button {
    border: none;
    border-radius: 10px;
    margin-top: 10px;
    width: 100%;
    height: 40px;
    background-color: #f27930;
    color: white;
}

.content-heading {
    font-size:48px !important;
    line-height: 1.2;
    margin-top: 60px !important;
}

.content-btn {
    padding: 15px 40px;
}
a{
    text-decoration: none;
    color: black;
}
/* Media queries for responsiveness */
@media (max-width: 768px) {
    .popup-content {
        max-width: 60%;
    }
}

@media (max-width: 576px) {
    .popup-content {
        max-width: 95%;
    }
}


.mini-section {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
}

.mini-section .content {
    background-color: #ffffff;
    padding: 20px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: auto;
    border-radius: 20px;
    text-align: center;
    border: 1px solid #ccc;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.mini-section .content p {
    font-size: 12px;
    font-weight: normal;
    margin-top: 10px;
    margin-bottom: 0;
    line-height: 1.4;
}

.mini-section .content h6 {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    text-transform: capitalize;
}

.mini-section .content i {
    font-size: 22px;
}


.mini-section-heading h5 {
    font-size: 30px;
    font-weight: bold;
}

.mini-section-heading {
    text-align: center;
}


@media (max-width: 768px) {
    .mini-section .content {
        width: 100%;
    }
}

body {
    margin: 0;            /* Reset default margin */
}
iframe {
    display: block;       /* iframes are inline by default */
    background: #000;
    border: none;         /* Reset default border */
    height: 100vh;        /* Viewport-relative units */
    width: 100vw;
}