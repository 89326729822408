/* inner menu */
   .nav-container {
    display: flex;
    align-items: center;
   }

  
  .mega-menu {
    position: absolute;
    width: 100%;
    top: 70px;
    left: 0;
    min-width: 100%;
    z-index: 999999;
  }
  
  .mega-menu .content {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 20px;
    border-bottom: 1px solid #f2f2f2;
  }
  
  .mega-menu .content .container {
    max-width: 1000px;
  }
  
  .nav-link i{
    margin-left: 10px;
  }
  
  
  nav ul .mega-menu {
    visibility: hidden;
    opacity: 0;
  }
  
  nav ul li:hover .mega-menu {
    visibility: visible;
    opacity: 1;
    transition: all 0.4s ease;
  }
  
  .sub-content-row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  
  .sub-content {
    border-radius: 20px;
    max-width: 360px;
    padding: 15px;
    margin-right: 25px;
    margin-bottom: 25px;
  }
  
  .sub-content:hover {
    background-color: #f7f8f9;
  }
  
  .sub-content a {
    text-decoration: none;
    color: #171a21;
  }
  
  .sub-content:hover h6 {
    color: #143a72;
    font-weight: bold;
  }
  
  .sub-content:hover .sub-item .icon i {
    color: #143a72;
  }
  
  .sub-content h6 {
    font-size: 16px;
    font-weight: normal;
    color: #171a21;
  }
  
  .sub-content p {
    font-size: 13px;
    line-height: 20px;
    margin-top: 10px;
    margin-bottom: 0;
    color: #8690a3;
    font-weight: normal;
  }
  
  .sub-item {
    display: flex;
  }
  
  .sub-item .icon i {
    font-size: 24px;
    height: 50px;
    width: 50px;
    background-color: #f7f8f9;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
  }
  
  .sub-content a {
    padding: 0;
  }
  