body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.font-bold {
  font-weight: 700 !important;
}

.body-background-color  {
  background-color:  rgba(59,130,246,.1);
}

.button-color {
  background-color: #f27930 !important;
  border-color: #f27930 !important;
  font-weight: bold !important;
  border-radius: 2rem !important;
}
.header-button-color {
  background-color: white!important;
  border-color: Black !important;
  border-radius: 3rem !important;
  color:#000000;
}

.customer-button-color {
  background-color: white!important;
  border-color:  #f27930 !important;
  border-radius: 3rem !important;
}
.customer-label-color {
  
  font-weight: bold !important;

  color: #f27930 !important;
}

.outline-button-color {
  border-color: lightgrey !important;
  background-color: #fff !important;
  color: #f27930 !important;
}

.label-color {
  
  font-weight: bold !important;

  color: #000000 !important;
}

.button-hover-color {
  outline: 3px solid black;
}
.benefits-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top:20px;
}
 
.benefit-box {
  border: 12px solid #f27930;
  padding: 5px;
  margin: 10px;
  width: 220px;
  text-align: center;
  background-color:  #143a72;
  color:white;
}


.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.crossline {
  width: 100%;
  border: 1px solid #e6e7e8;
}

.content-text {
  padding-left: 0.5rem;
  color: rgb(107, 114, 128, 1);
  font-size: 1rem;
  font-weight:500;
  margin-bottom: 40px;
}

input[type='checkbox'] {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px #0275d8;
}