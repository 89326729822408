.faq p{
text-align: left;
}

.faq h3{
    font-size: 2rem;
    }


.faq-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    gap: 15px;
}


.faq-content {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 20px;
    width: 1100px;
    max-width: 100%;
    margin: 0 auto;
    border: 1px solid #ccc;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.faq-content h6 {
    font-size: 22px;
    font-weight: normal;
    margin-bottom: 10px;
}

.faq-heading h5 {
    font-size: 26px;
    font-weight: bold;
}

.disclamer-section p {
    opacity: 0.5;
    font-size: 13px;
    margin-top: 30px !important;
}