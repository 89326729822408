
.search-radio-btns {
    display: flex;
    gap: 27px;
}
.inputdata {
    font-family: 'helveticaregular','Inter';
    width: 100%;
    border: 0;
    outline: 0;
    color: #333;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 12px;
}
.InputDiv {
    width: 100%;
    border-bottom: 2px solid #e6e7e8;
}